import React, { useEffect } from "react";
import { graphql } from "gatsby";
import Layout from "@components/layout";

// Hooks Querys & Context
import { QueryFragments } from "@querys/queryFragments"; // eslint-disable-line
import { useTracking } from "@lib/tracking";
import cx from "classnames";
import { PageMeta } from "@components/pageMeta";
import ModuleZone from "~moduleZone";
import { PageHeader } from "@components/pageHeader";

const ModularPage = ({ data: { page } }) => {
  const { pageModules, config, seo, title, hideNav } = page || {};
  const { track } = useTracking();

  useEffect(() => {
    track("Viewed Page", {
      page: title,
    });
  }, []);
  return (
    <Layout {...config} className="relative">
      <PageMeta {...seo} />
      <PageHeader hideNav={hideNav} />
      {pageModules && <ModuleZone {...pageModules} />}
    </Layout>
  );
};

export default ModularPage;

export const pageQuery = graphql`
  query modularPageQuery($slug: String!) {
    page: sanityModularPage(slug: { current: { eq: $slug } }) {
      title
      seo {
        ...SEO
      }
      pageModules: modules {
        ...PageModules
      }
      hideNav
    }
  }
`;
